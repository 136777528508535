import React, { PureComponent, Fragment } from "react";
import cx from "classnames";
import { navigate } from "gatsby";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

import "../styles/main.scss";
import styles from "./page.module.scss";

export default class FourOFourPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loaded: true
      });
      const loaderElem = document.getElementById("loader");
      if (loaderElem) {
        loaderElem.parentNode.removeChild(loaderElem);
      }
    }, 0);
  }

  render() {
    const { loaded } = this.state;
    return (
      <Fragment>
        <Seo title="404: Not found" />
        <div className={cx(styles.PageWrapper, { [styles.Loaded]: loaded })}>
          <Navbar
            goTo={page => {
              navigate(`/#${page}`);
            }}
            pathname="404"
          />
          <div
            className={cx(styles.Page, {
              [styles.Active]: loaded
            })}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                margin: "auto"
              }}
            >
              <h3 style={{ color: "#12122a" }}>NOT FOUND</h3>
              <p style={{ color: "#12122a" }}>
                You found a page that doesn&#39;t exist...
              </p>
            </div>
          </div>
          <Footer
            goTo={page => {
              navigate(`/#${page}`);
            }}
          />
        </div>
      </Fragment>
    );
  }
}
